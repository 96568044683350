import React from 'react'
import { TOTAL_REVIEW_COUNT, REVIEW_AVG } from '../consts'

function HeroRatingStars({ theme, centerAll, classes = "", hideBorder }) {

  const Star = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className=" w-5 h-5">
    <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" />
  </svg>

  return (
    <div className={`${centerAll && 'md:text-center'} text-left ${theme == 'light' ? 'text-white' : (theme == 'orange' ? 'text-white' : 'text-brand-800')}`}>
      <div className={`  font-normal  flex space-x-4 items-center ${classes}`}>
        <div className={`flex items-center  ${centerAll ? 'justify-center' : 'justify-center md:justify-start'} ${theme == 'orange' ? 'text-orange-500' : theme == 'light' ? 'text-white ' : 'text-gray-700'}`}>
          {new Array(5).fill('').map((item) => <Star />)}
        </div>
        {!hideBorder && <div className={`w-[2px] h-[40px] opacity-50  ${theme == 'light' ? ' bg-white' : ' bg-gray-800'}`} />}
        <span
          itemScope
          className="rating-desc"
          itemType="https://schema.org/Product"
        >
          <span itemProp="name" className='block ' >KYPD Kentucky Plumbing</span>{' '}
          <span
            itemScope
            itemProp="aggregateRating"
            itemType="https://schema.org/AggregateRating"
          >
            rated <span itemProp="ratingValue">{REVIEW_AVG}</span>/5{' '}
            based on <span itemProp="reviewCount">
              {TOTAL_REVIEW_COUNT}
            </span>{' '}
            reviews.{' '}
          </span>
        </span>
      </div>
    </div>
  )

}

export default HeroRatingStars
